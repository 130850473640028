<template>
  <h1 class="text-2xl font-semibold text-gray-900">Statistieken - Klanten heatmap</h1>
  <UISelectFixed type="HEATMAP_BLEND_LEVEL" v-model="radius" />
  <div class="py-4">
    <iframe
      border="0"
      :src="`${baseUrl}/widgets-golang/heatmap.html?radius=${radius}`"
      class="rounded-lg min-w-full w-full"
      style="height: calc(100vh - 250px); min-height: 400px"
    ></iframe>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

const baseUrl = process.env.VUE_APP_BASE_URL_HEATMAP || ''
const radius = ref(0.03)
</script>
