<template>
  <h1 class="text-2xl font-semibold text-gray-900">Tracking</h1>
  <UISelectChauffeur nullable label active v-model="chauffeur" />
  <div class="py-4">
    <iframe
      id="iframe-gps"
      border="0"
      :src="`${baseUrl}/widgets-golang/gps.html${chauffeur ? `?chauffeur=${chauffeur}` : ''}`"
      class="rounded-lg min-w-full w-full"
      style="height: calc(100vh - 250px); min-height: 400px"
    ></iframe>
  </div>
</template>

<script setup>
import UISelectChauffeur from '@/components/UI/Select/Chauffeur'
import { ref } from 'vue'

// import { useStore } from 'vuex'
// const store = useStore()

const baseUrl = process.env.VUE_APP_BASE_URL_TRACKING || ''
const chauffeur = ref(null)

// const onLoad = () => {
//   var frame = window.frames['iframe-gps'].contentWindow
//   frame.setChauffeurs(store.state.chauffeurs)
// }
</script>
